<template>
  <router-view :key="$route.params.id"></router-view>
</template>

<script setup>
import { useAuth } from "@/state/modules/auth";

const auth = useAuth();
auth.loadApp();
if (auth.isAuth) auth.getMe();
</script>
